import './assets/App.css';
import {
    Suspense,
    lazy
} from 'react';
import {
    BrowserRouter,
    Switch,
    Route
} from 'react-router-dom';
import LoadingGif from './media/loading.gif';
import ToTopButton from "./components/ToTopButton/ToTopButton";
import NotFound from "./pages/404.js";

const HomePage = lazy(() => import('./pages/HomePage'));
const Courses = lazy(() => import('./pages/Courses'));
const OurPower = lazy(() => import('./pages/OurPower'));
const WhyICode = lazy(() => import('./pages/WhyICode'));

function App() {
    return (
        <div>
            <Suspense fallback={
                <div style={{height: "100vh", width: "100%"}}
                     className="d-flex justify-content-center align-items-center">
                    <img src={LoadingGif}
                         alt="loadingImage"
                         style={{width: "150px"}}
                    />
                </div>
            }>
                <BrowserRouter>
                    <Switch>
                        <Route path="/" exact render={() => <HomePage/>}/>
                        <Route path="/courses" exact render={() => <Courses/>}/>
                        <Route path="/our-power" exact render={() => <OurPower/>}/>
                        <Route path="/why-icode" exact render={() => <WhyICode/>}/>
                        <Route component={NotFound} />
                    </Switch>
                </BrowserRouter>
                <ToTopButton />
            </Suspense>
        </div>
    );
}

export default App;
