import React from "react";
import Img404 from "../media/404.gif"

function NotFound() {
    return(
        <div className={"vh-100 d-flex justify-content-center align-items-center"}>
            <img src={Img404} alt={"404"}/>
        </div>
    )
}

export default NotFound;