import React, {useEffect} from "react";
import AOS from "aos";
import "./ToTopButton.css"
import "aos/dist/aos.css";
import {Button} from "reactstrap";

function ToTopButton() {

    useEffect(() => {
        AOS.init();
    }, []);



    return (
        <>
            <a href="#main">
                <Button data-aos="fade-up" data-aos-duration="800" id="fixedButton" className="btn border-0"><i
                    className="bi bi-arrow-up text-white"/></Button>
            </a>
        </>
    )
}

export default ToTopButton;